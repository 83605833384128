































import { Component, Prop, Vue } from "vue-property-decorator";
import { StampsStatistic } from "@/models/entities/stamps-statistic.interface";
import { CONDITIONS_ICON, CONDITIONS_TYPE } from "@/helpers/conditions";

const CPieChart = () =>
  import(/* webpackChunkName: "PieChart" */ "./CPieChart.vue");

@Component({
  components: {
    "c-pie-chart": CPieChart,
  },
})
export default class CChart extends Vue {
  @Prop({ required: true, type: Array })
  private items: StampsStatistic[];

  private series = [] as number[];
  private completeItems = [] as StampsStatistic[];

  private created(): void {
    this.series.length = 0;
    this.completeItems.length = 0;
    for (const el of [
      { stampInClass: "success", nr: 0 },
      { stampInClass: "warning", nr: 0 },
      { stampInClass: "error", nr: 0 },
    ]) {
      const item = this.items.find((i) => i.stampInClass === el.stampInClass);
      this.series.unshift(item ? item.nr : el.nr);
      this.completeItems.push(item ? item : el);
    }
  }

  private getNum(number: number): string {
    const n = Number(number);
    const length = number.toString().length;
    const ranges = [
      { l: 6, suffix: "k" },
      { l: 9, suffix: "M" },
      { l: 12, suffix: "B" },
    ];
    for (let i = 0; i < ranges.length; i++) {
      if (length > 3 && length <= ranges[i].l)
        return (
          (n / Math.pow(10, ranges[i].l - 3)).toString() + ranges[i].suffix
        );
    }
    return n.toString();
  }

  private getChipType(stampInClass: string): string {
    return CONDITIONS_TYPE(stampInClass);
  }

  private getChipIcon(stampInClass: string): string {
    return CONDITIONS_ICON(stampInClass);
  }

  private getChipLabel(stampInClass: string): string {
    if (!stampInClass) return "";
    switch (stampInClass.toLowerCase()) {
      case "success":
        return "SI";
      case "warning":
        return "PROBLEMA";
      case "error":
        return "NO";
      default:
        return "";
    }
  }
}
